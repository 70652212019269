<template>
  <div class="mt-4">
    <div class="d-flex mt-4 align-items-center justify-content-between">
      <h1 class="page-title">
        <b-icon icon="server" />
        {{ $t("qres.title") }}
      </h1>
      <router-link to="/admin/qres/new">
        <b-button variant="primary" data-cy="create-qre-button" pill>
          <b-icon-plus-circle class="mr-2" />
          {{ $t("qres.newQre") }}
        </b-button>
      </router-link>
    </div>

    <b-card border-variant="light" class="mt-4 shadow-sm card-rounded">
      <b-form @submit.prevent="search">
        <b-form-group>
          <b-input-group size="sm">
            <b-form-input
              id="filter-input"
              v-model="filter"
              autocomplete="off"
              data-cy="search-input"
              :placeholder="$t('typeToSearch')"
              type="search"
            />

            <b-input-group-append>
              <b-button data-cy="user-clear-search-button" @click="clearFilter">
                <b-icon-x />
              </b-button>

              <b-button
                variant="primary"
                data-cy="user-search-button"
                type="submit"
              >
                {{ $t("search") }}
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-form>

      <b-table
        :busy="loading"
        :current-page="currentPage"
        :fields="fields"
        :head-variant="'light'"
        :items="qres"
        :per-page="0"
        class="mb-0"
        data-cy="qres-table"
        fixed
      >
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle mr-2" />
            <strong>{{ $t("loading") }}</strong>
          </div>
        </template>

        <template #cell(name)="data">
          <router-link
            :style="
              data.item.status.toUpperCase() === 'UNKNOWN'
                ? 'pointer-events: none; color: gray; white-space: pre-wrap;'
                : 'white-space: pre-wrap;'
            "
            :to="'/admin/qres/' + data.item.qreId"
          >
            {{ data.value }}
          </router-link>
        </template>

        <template #cell(url)="data">
          <a
            :data-cy="`open-qre-notebook-${data.item.name}`"
            :href="data.item.url"
            :style="
              data.item.status.toUpperCase() !== 'RUNNING'
                ? 'pointer-events: none; color:gray;'
                : ''
            "
            target="_blank"
          >
            <b-icon class="mr-2" icon="terminal" />
            {{ $t("qres.openNotebook") }}
          </a>
        </template>

        <template #cell(status)="data">
          <b-badge
            :data-cy="`qre-status-table-badge-${data.item.name}`"
            :variant="statusVariant(data.item.status)"
          >
            {{ statusText(data.item.status) }}
          </b-badge>
          <b-icon
            v-if="
              data.item.status !== 'TRANSITIONING' &&
              data.item.editErrMsg !== null
            "
            v-b-tooltip.hover.right="data.item.editErrMsg"
            class="ml-2"
            icon="exclamation-triangle-fill"
            variant="warning"
          ></b-icon>
        </template>

        <template #cell(memory)="data">
          {{ byteToValueFormatter(data.item.memoryInBytes, true) }}
        </template>

        <template #cell(cores)="data">
          {{ coreFormatter(data.item.cores) }}
        </template>

        <template #cell(gpuIcon)="data">
          <GpuIcon
            v-if="data.item.gpus.length > 0"
            v-b-tooltip.hover
            :color="
              data.item.gpus.some((el) => el.status === 'BROKEN')
                ? '#fa5c7c'
                : '#6FBF94'
            "
            :height="25"
            :title="
              data.item.gpus.some((el) => el.status === 'BROKEN')
                ? 'GPU is broken'
                : 'GPU is connected'
            "
            :width="25"
          />
        </template>

        <template #cell(empty)="data">
          <div class="d-flex justify-content-end">
            <b-dropdown
              class="ml-2"
              variant="primary"
              pill
              right
              size="sm"
              text="Actions"
              toggle-class="purple-button rounded-pill"
            >
              <b-dropdown-item
                :disabled="
                  data.item.status.toUpperCase() !== 'RUNNING' &&
                  data.item.status.toUpperCase() !== 'STOPPED'
                "
                @click="goToEditQre(data.item.qreId)"
              >
                <b-icon class="mr-1" icon="pencil" />
                Edit QRE
              </b-dropdown-item>
              <b-dropdown-item
                :disabled="data.item.status.toUpperCase() !== 'RUNNING'"
                :to="`/admin/qres/${data.item.qreId}/terminal`"
              >
                <b-icon class="mr-1" icon="terminal" />
                {{ $t("rescueConsole.title") }}
              </b-dropdown-item>
              <b-dropdown-item
                :disabled="data.item.status.toUpperCase() !== 'RUNNING'"
                @click="openModalForFileUpload(data.item)"
              >
                <b-icon class="mr-1" icon="upload" />
                {{ $t("uploadFile.title") }}
              </b-dropdown-item>
              <b-dropdown-item
                :disabled="data.item.status.toUpperCase() !== 'RUNNING'"
                :to="`/admin/qres/${data.item.qreId}/logs`"
              >
                <b-icon class="mr-1" icon="file-text" />
                {{ $t("qres.logs") }}
              </b-dropdown-item>
              <b-dropdown-item
                :disabled="
                  data.item.status.toUpperCase() !== 'RUNNING' &&
                  data.item.status.toUpperCase() !== 'STOPPED'
                "
                @click="restartQre(data.item)"
              >
                <b-icon-bootstrap-reboot class="mr-1" />
                {{ $t("restart") }}
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>
      </b-table>

      <b-pagination
        v-if="total > pageSize"
        v-model="currentPage"
        :per-page="pageSize"
        :total-rows="total"
        align="right"
        class="mb-0 mt-3"
        first-text="First"
        last-text="Last"
        next-text="Next"
        prev-text="Prev"
      />

      <b-card-text
        v-if="qres.length === 0 && !loading"
        class="text-center my-4"
      >
        {{ $t("qres.emptyTable") }}
      </b-card-text>
    </b-card>
    <FileUploadModal :qre="qreModal" />
  </div>
</template>

<script>
import { byteToValueFormatter, coreFormatter } from "@/util/formatters";
import GpuIcon from "@/components/Icons/GpuIcon.vue";
import FileUploadModal from "@/components/Modals/FileUploadModal.vue";

export default {
  components: {
    GpuIcon,
    FileUploadModal,
  },
  name: "QREs",
  data() {
    return {
      qreModal: {},
      qres: [],
      currentPage: 1,
      total: null,
      pageSize: 10,
      fields: [
        {
          key: "name",
          label: this.$i18n.t("name"),
          tdClass: "align-middle qre-name",
        },
        {
          key: "url",
          label: this.$i18n.t("qres.notebook"),
          tdClass: "align-middle",
        },
        {
          key: "status",
          label: this.$i18n.t("status"),
          thStyle: { width: "150px", minWidth: "150px !important" },
          tdClass: "align-middle",
        },
        {
          key: "access",
          label: "Last Access Time",
          tdClass: "align-middle",
        },
        {
          key: "cores",
          label: "Cores",
          thStyle: { width: "100px", minWidth: "100px !important" },
          tdClass: "align-middle",
        },
        {
          key: "memory",
          label: "Memory",
          thStyle: { width: "100px", minWidth: "100px !important" },
          tdClass: "align-middle",
        },
        {
          key: "gpuIcon",
          label: "GPUs",
          thStyle: { width: "100px", minWidth: "100px !important" },
          tdClass: "align-middle",
        },
        {
          key: "empty",
          label: "",
          thStyle: { width: "70px", minWidth: "70px !important" },
          tdClass: "align-middle",
        },
      ],
      filter: null,
      loading: true,
    };
  },
  mounted() {
    this.getQres();
  },
  methods: {
    coreFormatter,
    byteToValueFormatter,
    goToEditQre(id) {
      this.$router.push(`/admin/qres/${id}`);
    },

    async getQres() {
      this.loading = true;
      this.total = 0;
      try {
        const {
          data: { content, page },
        } = await this.$http.get(
          this.$cfg.BASE_QRE_MANAGER_VERSION_URL + "/qres",
          {
            params: {
              page: this.currentPage - 1,
              pageSize: this.pageSize,
              search: this.filter,
            },
          }
        );
        this.qres = content.map((el) => {
          if (el.transitioning) {
            el.status = "TRANSITIONING";
          }

          el.editErrMsg = el.latestError;

          if (el.lastAccessTime !== 0) {
            el.access = new Date(el.lastAccessTime)
              .toLocaleString("en-GB")
              .replaceAll("/", "-");
          } else {
            el.access = "";
          }

          return el;
        });
        this.total = page.totalElements;
      } catch (err) {
        if (err.response) console.log(err.response);
      } finally {
        this.loading = false;
      }
    },

    async restartQre(qre) {
      this.qre = qre;
      try {
        this.qre.status = "TRANSITIONING";
        await this.$http.put(
          `${this.$cfg.BASE_QRE_MANAGER_URL}/v0/qre/${qre.qreId}/restart`,
          {
            errors: {
              404: () => true,
            },
          }
        );
        this.$root.$bvToast.toast(`QRE restarted!`, {
          title: `Success`,
          toaster: "b-toaster-bottom-right",
          variant: "success",
          solid: true,
        });
      } catch (err) {
        if (err.response.status === 404) {
          await this.$router.push("/qres");
        }
        if (err.response) {
          this.qre.status = "UNKNOWN";
          console.log(err.response);
        }
      }
      await this.getQres();
    },

    statusVariant(status) {
      switch (status.toUpperCase()) {
        case "RUNNING":
          return "success";
        case "STOPPED":
          return "danger";
        case "TRANSITIONING":
          return "warning";
        case "UNKNOWN":
        default:
          return "secondary";
      }
    },

    statusText(status) {
      return status.toUpperCase();
    },

    openModalForFileUpload(singleQre) {
      this.qreModal = singleQre;
      this.$bvModal.show("fileUploadModal");
    },

    search() {
      this.loading = true;
      this.currentPage = 1;
      this.getQres();
    },

    clearFilter() {
      this.filter = null;
      this.currentPage = 1;
      this.getQres();
    },
  },
  watch: {
    currentPage: {
      handler: function () {
        this.getQres();
      },
    },
  },
};
</script>
