var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-4"},[_c('div',{staticClass:"d-flex mt-4 align-items-center justify-content-between"},[_c('h1',{staticClass:"page-title"},[_c('b-icon',{attrs:{"icon":"server"}}),_vm._v(" "+_vm._s(_vm.$t("qres.title"))+" ")],1),_c('router-link',{attrs:{"to":"/admin/qres/new"}},[_c('b-button',{attrs:{"variant":"primary","pill":""}},[_c('b-icon-plus-circle',{staticClass:"mr-2"}),_vm._v(" "+_vm._s(_vm.$t("qres.newQre"))+" ")],1)],1)],1),_c('b-card',{staticClass:"mt-4 shadow-sm card-rounded",attrs:{"border-variant":"light"}},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.search.apply(null, arguments)}}},[_c('b-form-group',[_c('b-input-group',{attrs:{"size":"sm"}},[_c('b-form-input',{attrs:{"id":"filter-input","autocomplete":"off","placeholder":_vm.$t('typeToSearch'),"type":"search"},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}}),_c('b-input-group-append',[_c('b-button',{on:{"click":_vm.clearFilter}},[_c('b-icon-x')],1),_c('b-button',{attrs:{"variant":"primary","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t("search"))+" ")])],1)],1)],1)],1),_c('b-table',{staticClass:"mb-0",attrs:{"busy":_vm.loading,"current-page":_vm.currentPage,"fields":_vm.fields,"head-variant":'light',"items":_vm.qres,"per-page":0,"fixed":""},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center my-2"},[_c('b-spinner',{staticClass:"align-middle mr-2"}),_c('strong',[_vm._v(_vm._s(_vm.$t("loading")))])],1)]},proxy:true},{key:"cell(name)",fn:function(data){return [_c('router-link',{style:(data.item.status.toUpperCase() === 'UNKNOWN'
              ? 'pointer-events: none; color: gray; white-space: pre-wrap;'
              : 'white-space: pre-wrap;'),attrs:{"to":'/admin/qres/' + data.item.qreId}},[_vm._v(" "+_vm._s(data.value)+" ")])]}},{key:"cell(url)",fn:function(data){return [_c('a',{style:(data.item.status.toUpperCase() !== 'RUNNING'
              ? 'pointer-events: none; color:gray;'
              : ''),attrs:{"href":data.item.url,"target":"_blank"}},[_c('b-icon',{staticClass:"mr-2",attrs:{"icon":"terminal"}}),_vm._v(" "+_vm._s(_vm.$t("qres.openNotebook"))+" ")],1)]}},{key:"cell(status)",fn:function(data){return [_c('b-badge',{attrs:{"variant":_vm.statusVariant(data.item.status)}},[_vm._v(" "+_vm._s(_vm.statusText(data.item.status))+" ")]),(
            data.item.status !== 'TRANSITIONING' &&
            data.item.editErrMsg !== null
          )?_c('b-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",value:(data.item.editErrMsg),expression:"data.item.editErrMsg",modifiers:{"hover":true,"right":true}}],staticClass:"ml-2",attrs:{"icon":"exclamation-triangle-fill","variant":"warning"}}):_vm._e()]}},{key:"cell(memory)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.byteToValueFormatter(data.item.memoryInBytes, true))+" ")]}},{key:"cell(cores)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.coreFormatter(data.item.cores))+" ")]}},{key:"cell(gpuIcon)",fn:function(data){return [(data.item.gpus.length > 0)?_c('GpuIcon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"color":data.item.gpus.some(function (el) { return el.status === 'BROKEN'; })
              ? '#fa5c7c'
              : '#6FBF94',"height":25,"title":data.item.gpus.some(function (el) { return el.status === 'BROKEN'; })
              ? 'GPU is broken'
              : 'GPU is connected',"width":25}}):_vm._e()]}},{key:"cell(empty)",fn:function(data){return [_c('div',{staticClass:"d-flex justify-content-end"},[_c('b-dropdown',{staticClass:"ml-2",attrs:{"variant":"primary","pill":"","right":"","size":"sm","text":"Actions","toggle-class":"purple-button rounded-pill"}},[_c('b-dropdown-item',{attrs:{"disabled":data.item.status.toUpperCase() !== 'RUNNING' &&
                data.item.status.toUpperCase() !== 'STOPPED'},on:{"click":function($event){return _vm.goToEditQre(data.item.qreId)}}},[_c('b-icon',{staticClass:"mr-1",attrs:{"icon":"pencil"}}),_vm._v(" Edit QRE ")],1),_c('b-dropdown-item',{attrs:{"disabled":data.item.status.toUpperCase() !== 'RUNNING',"to":("/admin/qres/" + (data.item.qreId) + "/terminal")}},[_c('b-icon',{staticClass:"mr-1",attrs:{"icon":"terminal"}}),_vm._v(" "+_vm._s(_vm.$t("rescueConsole.title"))+" ")],1),_c('b-dropdown-item',{attrs:{"disabled":data.item.status.toUpperCase() !== 'RUNNING'},on:{"click":function($event){return _vm.openModalForFileUpload(data.item)}}},[_c('b-icon',{staticClass:"mr-1",attrs:{"icon":"upload"}}),_vm._v(" "+_vm._s(_vm.$t("uploadFile.title"))+" ")],1),_c('b-dropdown-item',{attrs:{"disabled":data.item.status.toUpperCase() !== 'RUNNING',"to":("/admin/qres/" + (data.item.qreId) + "/logs")}},[_c('b-icon',{staticClass:"mr-1",attrs:{"icon":"file-text"}}),_vm._v(" "+_vm._s(_vm.$t("qres.logs"))+" ")],1),_c('b-dropdown-item',{attrs:{"disabled":data.item.status.toUpperCase() !== 'RUNNING' &&
                data.item.status.toUpperCase() !== 'STOPPED'},on:{"click":function($event){return _vm.restartQre(data.item)}}},[_c('b-icon-bootstrap-reboot',{staticClass:"mr-1"}),_vm._v(" "+_vm._s(_vm.$t("restart"))+" ")],1)],1)],1)]}}])}),(_vm.total > _vm.pageSize)?_c('b-pagination',{staticClass:"mb-0 mt-3",attrs:{"per-page":_vm.pageSize,"total-rows":_vm.total,"align":"right","first-text":"First","last-text":"Last","next-text":"Next","prev-text":"Prev"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}):_vm._e(),(_vm.qres.length === 0 && !_vm.loading)?_c('b-card-text',{staticClass:"text-center my-4"},[_vm._v(" "+_vm._s(_vm.$t("qres.emptyTable"))+" ")]):_vm._e()],1),_c('FileUploadModal',{attrs:{"qre":_vm.qreModal}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }